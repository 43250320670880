import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type ISearchParams, OPERATOR_SORT } from '@features/filters/types';

interface IInitialState {
  searchParams: ISearchParams;
}

export const initialCampaignMonitorSearchParams = {
  page: 1,
  size: 10,
  sorts: [
    {
      order: OPERATOR_SORT.DESC,
      field: 'trigger_received_at',
    },
  ],
  filters: [
    //Description: BS-11740 uncomment after v4.10 creation
    // {
    //   value: false,
    //   type: OPERATOR_TYPE.EQUAL,
    //   field: CAMPAIGN_MONITOR_REPORT_FILTERS_NAME.HIDDEN,
    // },
  ],
};

const initialState: IInitialState = {
  searchParams: initialCampaignMonitorSearchParams,
};

const campaignMonitorSlice = createSlice({
  initialState,
  name: 'campaignMonitorSlice',
  reducers: {
    setSearchParams(
      state: IInitialState,
      action: PayloadAction<ISearchParams>
    ) {
      state.searchParams = action.payload;
    },
  },
});

export default campaignMonitorSlice.reducer;

export const { setSearchParams } = campaignMonitorSlice.actions;
